import React, { ReactNode } from 'react';
import { SxProps, Box } from '@mui/material';
import { FC } from 'react';
interface GlassBoxTypes {
  sx?: SxProps;
  children?: ReactNode;
  className?: string;
}

export const GlassBox: FC<GlassBoxTypes> = ({
  children,
  sx,
  className,
  ...rest
}) => {
  const defaultStyles: SxProps = {
    background: 'rgba(255, 255, 255, 0.06)',
    backdropFilter: 'blur(17px)',
    borderRadius: 3,
    border: '1px solid rgba(255, 255, 255, 0.2)',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
  };

  return (
    <Box
      {...rest}
      sx={{ ...defaultStyles, ...sx }}
      className={className}
    >
      {children}
    </Box>
  );
};
