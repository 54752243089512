import React, { FC } from 'react';
import { InputProps, OutlinedInput } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { useStyles } from './styles';

interface TextInputProps extends InputProps {}

export const SearchBar: FC<TextInputProps> = ({ ...rest }, error) => {
  const classes = useStyles();
  return (
    <OutlinedInput
      notched={true}
      {...rest}
      type={'text'}
      className={classes.inputStyles}
      label=''
      startAdornment={<SearchIcon sx={{ paddingRight: '10px' }} />}
    />
  );
};
