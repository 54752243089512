export const Colors = {
  primary: '#2F2B43',
  primaryLow: '#26272B',
  secondary: '#2A263E',
  lightWhite: '#F2F2F3',
  pink: '#F05D9E',
  lightGrey: '#A6A6A6',
  background: '#2A263E',
  white: '#fff',
  black: '#000',
  gradientSecondary: '#E35DDD',
  gradientPrimary: '#7561FF',
  glass: 'rgba(255, 255, 255, 0.05)',
  lightGlass: '#FFFFFF1A',
  inputGrey: '#3F3F46',
  darkGrey: '#70707B',
  primaryGlass: 'rgba(255, 255, 255, 0.03)',
};
