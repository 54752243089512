import React, { FC } from 'react';
import { Backdrop, CircularProgress, BackdropProps } from '@mui/material';
import { Colors } from 'global-styles';

interface Props extends BackdropProps {
  open: boolean;
}
export const BackDrop: FC<Props> = ({ open, ...rest }) => {
  return (
    <Backdrop
      sx={{ color: Colors.white, zIndex: 11 }}
      {...rest}
      open={open}
    >
      <CircularProgress color='inherit' />
    </Backdrop>
  );
};
