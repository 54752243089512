import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';

export const useStyles = makeStyles((theme: Theme) => ({
  socialRow: {
    // justifyContent: "center",
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
    gap: 10,
  },
}));
