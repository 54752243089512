import { makeStyles } from "@mui/styles";
import { Theme } from "@mui/material";

export const useStyles = makeStyles((theme: Theme) => ({
  bodyView: {
    flexDirection: "column",
    zIndex: 1,
    display: "flex",
    height: "100%",
    width: "100%",
  },
}));
