import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';
import { Colors } from 'global-styles';

export const useStyles = makeStyles((theme: Theme) => ({
  containerStyles: {
    backgroundColor: Colors.glass,
    width: '100%',
    borderRadius: '5px',
    '& fieldset': {
      borderColor: Colors.darkGrey,
    },
    '&:hover fieldset': {
      borderColor: Colors.white,
    },
    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderColor: Colors.white,
    },
    '&:hover .MuiOutlinedInput-notchedOutline': {
      borderColor: Colors.white,
    },
    '&.Mui-focused fieldset': {
      borderColor: Colors.white,
    },
  },
  selectStyle: {
    color: 'white',
    height: '50px',
    borderColor: 'red',
  },
  menuItemStyle: {
    borderBottom: 0,
    color: 'white',
    textAlign: 'center',
    fontWeight: '500',
  },
}));
