import React, { FC, useState } from 'react';
import { VisibilityOff, Visibility } from '@material-ui/icons';
import {
  IconButton,
  InputAdornment,
  InputProps,
  OutlinedInput,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { Colors } from 'global-styles';

interface TextInputProps extends InputProps {
  error?: boolean;
}

const CustomOutlinedInput = styled(OutlinedInput)(({ theme, error }: any) => ({
  background: Colors.white + 10,
  borderRadius: '5px',
  color: 'white',
  fontSize: '20px',
  fontWeight: '600',
  height: '50px',
  width: '100%',
  borderWidth: '1px',
  '&:hover': {
    background: Colors.white + 20,
  },
  '& fieldset': {
    borderColor: error ? 'red' : Colors.darkGrey,
  },
  '&:hover fieldset': {
    borderColor: Colors.white,
  },
  '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
    borderColor: Colors.white,
  },
  '&:hover .MuiOutlinedInput-notchedOutline': {
    borderColor: Colors.white,
  },
  '&.Mui-focused fieldset': {
    borderColor: Colors.white,
  },
  '& input:-webkit-autofill': {
    WebkitBoxShadow: '0 0 0 1000px rgba(0, 0, 0, 0) inset',
    WebkitTextFillColor: 'white',
    transition: 'background-color 5000s ease-in-out 0s',
  },
  '& input:-webkit-autofill:hover, & input:-webkit-autofill:focus, & input:-webkit-autofill:active':
    {
      WebkitBoxShadow: '0 0 0 1000px rgba(0, 0, 0, 0) inset',
      WebkitTextFillColor: 'white',
      transition: 'background-color 5000s ease-in-out 0s',
    },
}));

export const TextInput: FC<TextInputProps> = ({
  error,
  type = 'text',
  ...rest
}) => {
  const [showText, setShowText] = useState(type === 'text');

  const togglePasswordVisibility = () => {
    setShowText(!showText);
  };

  return (
    <CustomOutlinedInput
      notched
      {...rest}
      type={showText ? 'text' : 'password'}
      error={Boolean(error)}
      endAdornment={
        type === 'password' && (
          <InputAdornment position='end'>
            <IconButton
              onClick={togglePasswordVisibility}
              edge='end'
            >
              {showText ? (
                <Visibility style={{ color: Colors.lightGrey }} />
              ) : (
                <VisibilityOff style={{ color: Colors.lightGrey }} />
              )}
            </IconButton>
          </InputAdornment>
        )
      }
    />
  );
};
