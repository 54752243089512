import { makeStyles } from "@mui/styles";
import { Theme } from "@mui/material";

export const useStyles = makeStyles((theme: Theme) => ({
  gridContainer: {
    padding: "20px !important",
    boxSizing: "border-box",
    width: "100%",
  },
  gridItem: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100%",
  },
  reachText: {
    fontWeight: "bold",
    fontSize: "20px",
    textAlign: "left",
  },
}));
