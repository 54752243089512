import { Box, Grid, Typography } from '@mui/material';
import React, { FC } from 'react';
import { useStyles } from './styles';
import {
  verification1x,
  verification2x,
  verification3x,
  creator,
} from 'assets';
import { Language, LocationOnOutlined } from '@material-ui/icons';
import { BaseButton, SocialCount } from 'components';

interface Props {}
export const ProfileBody: FC<Props> = () => {
  const classes = useStyles();

  const Tags: FC<{ tag: string }> = ({ tag }) => {
    return (
      <Box className={classes.tagView}>
        <Typography className={classes.tagText}>#{tag}</Typography>
      </Box>
    );
  };
  return (
    <Box className={classes.mainContainer}>
      <Grid
        container
        rowSpacing={1}
        columnSpacing={{ xs: 1, sm: 2, md: 3 }}
        marginTop={20}
      >
        <Grid
          item
          xs={1}
        />
        <Grid
          item
          xs={6}
        >
          <Box className={classes.leftContainer}>
            <Box className={classes.rowContainer}>
              <Typography className={classes.nameText}>
                Andrew Greenald
              </Typography>
              <img
                src={verification1x}
                srcSet={`${verification1x} 1x, ${verification2x} 2x, ${verification3x} 3x`}
                alt='Descriptive text'
              />
            </Box>
            <SocialCount />

            <Box className={classes.rowContainer}>
              <Tags tag='Content Creator' />
              <Tags tag='Youth Speaker' />
              <Tags tag='TED talks' />
            </Box>

            <Typography className={classes.aboutText}>About</Typography>

            <Box className={classes.rowContainer}>
              <LocationOnOutlined />
              <Typography>United Kingdom</Typography>
              <Language />
              <Typography>www.socialmedia.com/aboutme</Typography>
            </Box>

            <Typography className={classes.bioText}>
              Lorem Ipsum is simply dummy text of the printing and typesetting
              industry. Lorem Ipsum has been the industry's standard dummy text
              ever since the 1500s, when an .Lorem Ipsum is simply dummy text of
              the printing and typesetting industry. Lorem Ipsum has been the
              industry's standard dummy text ever since the 1500s, when an Lorem
              Ipsum is simply dummy text of the printing and typesetting
              industry. Lorem Ipsum has been the industry's standard dummy text
              ever since the 1500s, when an
            </Typography>
            <BaseButton className={classes.messageBtn}>Message Me</BaseButton>
          </Box>
        </Grid>
        <Grid
          item
          xs={4}
        >
          <Box className={classes.rightContainer}>
            <img
              src={creator}
              alt='Descriptive text'
              className={classes.imageStyle}
            />
          </Box>
        </Grid>
        <Grid
          item
          xs={1}
        />
      </Grid>
    </Box>
  );
};
