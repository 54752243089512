import { registrationType } from 'types';
import validator from 'validator';

export const validateEmail = (email: string) => {
  if (!validator.isEmail(email.trim().toLowerCase())) return false;
  return true;
};

export const validatePassword = (password: string): boolean => {
  if (validator.isEmpty(password) || password.length < 8) return false;
  return true;
};
export const validateRePassword = (
  password: string,
  rePassword: string
): boolean => {
  if (
    validator.isEmpty(rePassword) ||
    rePassword.length < 8 ||
    rePassword !== password
  )
    return false;
  return true;
};
export const validateFullName = (fullName: string): boolean => {
  if (validator.isEmpty(fullName)) return false;
  return true;
};
export const validateRegistrationForm = ({
  fullName,
  email,
  password,
}: registrationType): boolean => {
  if (validator.isEmpty(fullName)) return false;
  if (!validator.isEmail(email)) return false;
  if (validator.isEmpty(password) || password.length < 8) return false;

  return true;
};

export const validateLoginForm = ({
  email,
  password,
}: registrationType): boolean => {
  if (!validator.isEmail(email)) return false;
  if (validator.isEmpty(password) || password.length < 8) return false;

  return true;
};
