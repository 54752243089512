import React, { FC } from "react";
import { Box, List, ListItem, ListItemIcon } from "@mui/material";
import { Colors } from "global-styles";
import { useNavigate } from "react-router-dom";
import { useStyles } from "./styles";

type tab = {
  name: string;
  icon: string[];
  navigateTo: string;
  heading?: string;
  subHeading?: string;
};

interface Props {
  activeTab?: tab;
  setActiveTab?: (val: tab) => void;
  sidebarData?: tab[];
}

export const DashboardSidebar: FC<Props> = ({
  activeTab,
  setActiveTab,
  sidebarData,
}) => {
  const navigate = useNavigate();
  const classes = useStyles();

  const _handleOnClick = (item) => {
    setActiveTab(item);
    navigate(item.navigateTo);
  };

  return (
    <List className={classes.listContainer}>
      {sidebarData.map((item, index) => (
        <ListItem
          key={index}
          disablePadding
          onClick={() => _handleOnClick(item)}
          className={classes.listItemContainer}
        >
          <ListItemIcon
            className={classes.listIcon}
            sx={{
              borderTop:
                item.name === "Facebook"
                  ? `1px solid ${Colors.darkGrey}`
                  : "0px",
              paddingTop: item.name === "Facebook" ? `8px` : "0px",
            }}
          >
            <Box
              className={classes.imgBox}
              sx={{
                background:
                  activeTab?.name === item.name
                    ? `linear-gradient(to right, ${Colors.gradientPrimary}, ${Colors.gradientSecondary})`
                    : "transparent",
              }}
            >
              <img
                src={item?.icon[0]}
                srcSet={`${item?.icon[0]} 1x, ${item?.icon[1]} 2x, ${item?.icon[2]} 3x`}
                alt={`${item.name} icon`}
              />
            </Box>
          </ListItemIcon>
        </ListItem>
      ))}
    </List>
  );
};
