import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';
import { Colors } from 'global-styles';

export const useStyles = makeStyles((theme: Theme) => ({
  mainContainer: {
    overflowY: 'auto',
    zIndex: 1,
    '&::-webkit-scrollbar': { display: 'none' },
    padding: '30px !important',
  },
  headerRowButton: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    width: '100%',
  },
}));
