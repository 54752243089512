import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';
import { Colors } from 'global-styles';

export const useStyles = makeStyles((theme: Theme) => ({
  mainContainer: {
    display: 'flex',
    backgroundColor: Colors.primaryGlass,
    padding: '10px !important',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-around',
    width: '100%',
    zIndex: 1,
    // position: 'fixed',
    // top: 0,
    // left: 0,
    // right: 0,
    // zIndex: 1000,
    // boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
    // backdropFilter: 'blur(30px)',
  },
  creatorBtn: {
    background: `linear-gradient(to right, ${Colors.gradientPrimary}, ${Colors.gradientSecondary}) no-repeat`,
  },
  menuBtn: {
    minWidth: 100,
    padding: '10px',
    boxSizing: 'border-box',
    fontWeight: '500',
    marginRight: 10,
    '&:hover': {
      fontWeight: 'bold',
      background: Colors.lightGlass,
      padding: '10px !important',
      borderRadius: '8px',
      cursor: 'pointer',
      transform: 'scale(1.05)',
      transition: 'transform 0.3s ease-in-out',
    },
  },
  leftSide: {
    flex: 0.9,
    justifyContent: 'flex-end',
    display: 'flex',
    flexDirection: 'row',
  },
}));
