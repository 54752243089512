import React, { FC } from 'react';
import { Box, Grid, Typography } from '@mui/material';
import { collab1, collab2, collab3, collab4, collab5, collab6 } from 'assets';
import { useStyles } from './styles';

interface Props {}
export const ProfileCollaborators: FC<Props> = () => {
  const classes = useStyles();

  return (
    <Grid
      container
      spacing={2}
      className={classes.gridContainer}
      justifyContent='center'
      alignItems='center'
    >
      <Grid
        item
        xs={12}
        marginBottom={10}
        marginLeft={10}
      >
        <Typography className={classes.reachText}>Collaborated With</Typography>
      </Grid>

      {[collab1, collab2, collab3, collab4, collab5, collab6].map(
        (collab, index) => (
          <Grid
            item
            xs={12}
            sm={6}
            md={4}
            lg={2}
            key={index}
          >
            <Box className={classes.imageGrid}>
              <img
                src={collab}
                alt={`Collaborator ${index + 1}`}
                className={classes.imageStyle}
              />
            </Box>
          </Grid>
        )
      )}
    </Grid>
  );
};
