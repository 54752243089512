import {
  createAsyncThunk,
  createSelector,
  createSlice,
} from '@reduxjs/toolkit';
import { RootState } from 'app-redux/store';

const initialState = {
  isLoading: false,
  homeData: null,
};

export const getHomeData = createAsyncThunk(
  'homeSlice/getHomeData',
  async (input, thunkAPI) => {
    try {
    } catch (error) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

export const homeSlice = createSlice({
  name: 'homeSlice',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getHomeData.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getHomeData.fulfilled, (state) => {
        state.isLoading = false;
      })
      .addCase(getHomeData.rejected, (state) => {
        state.isLoading = false;
      });
  },
});

const selectHomeState = (state: RootState) => state.homeSlice;

const selectIsLoadingHome = createSelector(
  selectHomeState,
  (state) => state.isLoading
);
const selectHomeData = createSelector(
  selectHomeState,
  (state) => state.homeData
);
export { selectHomeData, selectIsLoadingHome };

export default homeSlice.reducer;
