import React, { FC, useEffect, useReducer } from 'react';
import { Box, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch } from 'hooks';
import { BaseButton, GlassBox, SocialButton, TextInput } from 'components';
import { Colors } from 'global-styles';
import { registerUser, showAlert } from 'app-redux';
import {
  ALERT_TYPES,
  validateEmail,
  validateFullName,
  validatePassword,
  validateRegistrationForm,
} from 'utils';
import { google3x } from 'assets';
import { registrationFormType } from 'types';
import { useUserInfoStyles } from './styles';

const initialRegisterState = {
  fullName: '',
  email: '',
  password: '',
  isValid: false,
  fullNameError: false,
  emailError: false,
  passwordError: false,
};
export const UserInfo: FC = () => {
  const navigation = useNavigate();
  const dispatch = useAppDispatch();
  const classes = useUserInfoStyles();

  const [
    {
      fullName,
      fullNameError,
      email,
      emailError,
      password,
      passwordError,
      isValid,
    },
    setData,
  ] = useReducer(
    (state: registrationFormType, newState) => ({ ...state, ...newState }),
    initialRegisterState
  );

  const _handleChange = ({ target: { value, name } }) =>
    setData({ [name]: value });

  const fullNameValidation = () => {
    if (validateFullName(fullName)) setData({ fullNameError: false });
    else setData({ fullNameError: true });
  };
  const emailValidation = () => {
    if (validateEmail(email)) setData({ emailError: false });
    else setData({ emailError: true });
  };
  const passwordValidation = () => {
    if (validatePassword(password)) setData({ passwordError: false });
    else setData({ passwordError: true });
  };
  useEffect(() => {
    if (validateRegistrationForm({ fullName, email, password }))
      setData({ isValid: true });
    else setData({ isValid: false });
  }, [fullName, email, password]);

  const _handleRegistration = async () => {
    try {
      await dispatch(registerUser({ email, password, fullName })).unwrap();
    } catch (error) {
      dispatch(
        showAlert({
          isAlertVisible: true,
          alertType: ALERT_TYPES.ERROR,
          alertMessage: error,
        })
      );
    }
  };
  return (
    <GlassBox className={classes.middleBox}>
      <Box className={classes.headerText}>
        <Typography className={classes.text}>Create a New Account</Typography>
      </Box>
      <Box className={classes.columnView}>
        <Typography>Full Name</Typography>
        <TextInput
          type="text"
          name="fullName"
          value={fullName}
          error={fullNameError}
          className={classes.inputStyles}
          placeholder="Full Name"
          onChange={_handleChange}
          onBlur={fullNameValidation}
        />
      </Box>
      <Box className={classes.columnView}>
        <Typography>Email</Typography>
        <TextInput
          type="text"
          name="email"
          value={email}
          error={emailError}
          className={classes.inputStyles}
          placeholder="Email"
          onChange={_handleChange}
          onBlur={emailValidation}
        />
      </Box>
      <Box className={classes.columnView}>
        <Typography>Password</Typography>
        <TextInput
          name="password"
          error={passwordError}
          value={password}
          className={classes.inputStyles}
          placeholder="Password"
          type="password"
          onChange={_handleChange}
          onBlur={passwordValidation}
        />
      </Box>
      <BaseButton
        disabled={!isValid}
        onClick={_handleRegistration}
        className={classes.button}
        style={{
          background: isValid
            ? `linear-gradient(to right, ${Colors.gradientPrimary}, ${Colors.gradientSecondary}) no-repeat`
            : Colors.lightGlass,
          color: isValid ? 'white' : Colors.lightGlass,
        }}>
        Sign Up
      </BaseButton>
      <Box className={classes.orView}>
        <Box className={classes.orLine} />
        <Typography className={classes.orText}>OR</Typography>
        <Box className={classes.orLine} />
      </Box>
      <SocialButton logo={google3x} heading="Continue with Google" />
      <Typography
        color={'white'}
        variant="body2"
        className={classes.resendText}>
        {`Already have an account?`}{' '}
        <span
          style={{ fontWeight: 'bold', cursor: 'pointer' }}
          onClick={() => navigation('/sign-in')}>
          Sign In
        </span>
      </Typography>
    </GlassBox>
  );
};
