import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';
import { Colors } from 'global-styles';

export const useStyles = makeStyles((theme: Theme) => ({
  listContainer: {
    backgroundColor: Colors.primaryGlass,
    justifyContent: 'flex-start',
    alignItems: 'center',
    gap: '12px',
    borderRadius: '10px',
    width: 120,
    height: '100%',
    zIndex: 1,
    padding: 0,
    display: 'flex',
    flexDirection: 'column',
    paddingTop: '5px',
  },
  listItemContainer: {
    height: 52,
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '50%',
    cursor: 'pointer',
    transition: 'background-color 0.3s ease',
  },
  listIcon: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  imgBox: {
    height: 50,
    width: 50,
    alignItems: 'center',
    justifyContent: 'center',
    display: 'flex',
    borderRadius: '50%',
  },
}));
