import { makeStyles } from "@mui/styles";
import { Theme } from "@mui/material";
import { Colors } from "global-styles";

export const useStyles = makeStyles((theme: Theme) => ({
  mainContainer: {
    position: "relative",
    display: "flex",
  },
  middleBox: {
    width: "35%",
    height: "90%",
    background: `${Colors.glass} !important`,
    border: "0px",
    borderRadius: "20px",
    backdropFilter: "blur(17px)",
    zIndex: 10,
    justifyContent: "center",
    alignItems: "center",
    display: "flex",
  },
  headerText: {
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    margin: "none !important",
    alignItems: "flex-start",
    width: "80%",
  
  },
  columnView: {
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    margin: "none !important",
    alignItems: "flex-start",
    width: "80%",
    lineHeight: 1,
    marginTop: 20,
  },
  text: {
    color: "#fff",
    fontWeight: "600",
    fontSize: "25px",
    paddingBottom: 10,
    zIndex: 1,
  },
  inputStyles: {
    width: "100%",
    borderWidth: "1px",
    borderColor: "gray",
    fontSize: 15,
    fontWeight: "normal",
    borderRadius: "8px",
    height: "55px",
    marginTop: 15,
  },
  button: {
    marginTop: 20,
    marginBottom: 20,
    width: "80%",
    height: "50px",
    borderRadius: "8px",
    alignItems: "center",
    gap: 10,
    justifyContent: "center",
  },
  description: {
    fontSize: "14px",
    color: "#FFFFFF80",
  },
  resendText: {
    fontSize: "16px",
    zIndex: 1,
    color: `${Colors.lightWhite} !important`,
  },
  orView: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    gap: 10,
  },
  orLine: {
    display: "flex",
    width: "100px",
    height: "1px",
    background: `${Colors.darkGrey} !important`,
  },
  orText: {
    textAlign: "center",
    fontSize: "16px",
    color: "#FFFFFF",
  },
  appNameTextView:{
      display:'flex',
      position:'absolute',
      top:20,
      left:30
  }
}));
