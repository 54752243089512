import React, { FC, useState } from 'react';
import {
  CreatorSettings,
  DashboardHeader,
  DashboardHome,
  DashboardSidebar,
  LinkSocialPlatforms,
  SocialProfileDashboard,
} from './components';
import { BackDrop, FullScreenBox, PageBlur } from 'components';

import { Box } from '@mui/material';
import { sidebarData } from 'utils';
import { useStyles } from './styles';
import { DashboardFinance } from './components/dashboard-finance';
import { useAppSelector } from 'hooks';
import { RootState } from 'app-redux/store';

type tab = {
  name: string;
  icon: string[];
  navigateTo: string;
  heading?: string;
  subHeading?: string;
};

interface Props {}
export const CreatorDashboard: FC<Props> = () => {
  const classes = useStyles();
  const { isLogging } = useAppSelector((state: RootState) => state.authSlice);
  const [activeTab, setActiveTab] = useState<tab>(sidebarData[1]);

  return (
    <FullScreenBox flexDirection={'row'}>
      <DashboardSidebar
        sidebarData={sidebarData}
        setActiveTab={setActiveTab}
        activeTab={activeTab}
      />
      <Box className={classes.bodyView}>
        <DashboardHeader
          heading={activeTab?.heading}
          subheading={activeTab?.subHeading}
          icon={activeTab?.icon}
        />
        {activeTab.name === 'Dashboard' && <DashboardHome />}
        {activeTab.name === 'Link' && <LinkSocialPlatforms />}
        {activeTab.name === 'Chat' && <Box />}
        {activeTab.name === 'Finance' && <DashboardFinance />}
        {activeTab.name === 'Settings' && <CreatorSettings />}
        {activeTab.name === 'Facebook' && (
          <SocialProfileDashboard platformName="Facebook" />
        )}
        {activeTab.name === 'Instagram' && (
          <SocialProfileDashboard platformName="Instagram" />
        )}
        {activeTab.name === 'TikTok' && (
          <SocialProfileDashboard platformName="TikTok" />
        )}
        {activeTab.name === 'X' && <SocialProfileDashboard platformName="X" />}
        {activeTab.name === 'Twitch' && (
          <SocialProfileDashboard platformName="Twitch" />
        )}
        {activeTab.name === 'Youtube' && (
          <SocialProfileDashboard platformName="Youtube" />
        )}
      </Box>

      <PageBlur />
      <BackDrop open={isLogging} />
    </FullScreenBox>
  );
};
