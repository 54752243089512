import { makeStyles } from "@mui/styles";
import { Theme } from "@mui/material";

export const useStyles = makeStyles((theme: Theme) => ({
  mainContainer: {
    display: "flex",
  },
  labelStyle: {
    textAlign: "left",
    marginBottom: 12,
  },
}));
