export const followers1x = require('./icons/followers/followers.png');
export const followers2x = require('./icons/followers/followers@2x.png');
export const followers3x = require('./icons/followers/followers@3x.png');

export const verified1x = require('./icons/verified/verified.png');
export const verified2x = require('./icons/verified/verified@2x.png');
export const verified3x = require('./icons/verified/verified@3x.png');

export const google1x = require('./icons/google/google.png');
export const google2x = require('./icons/google/google@2x.png');
export const google3x = require('./icons/google/google@3x.png');

export const likes1x = require('./icons/likes/likes.png');
export const likes2x = require('./icons/likes/likes@2x.png');
export const likes3x = require('./icons/likes/likes@3x.png');

export const platform1x = require('./icons/platform/platform.png');
export const platform2x = require('./icons/platform/platform@2x.png');
export const platform3x = require('./icons/platform/platform@3x.png');

export const views1x = require('./icons/views/views.png');
export const views2x = require('./icons/views/views@2x.png');
export const views3x = require('./icons/views/views@3x.png');

export const verification1x = require('./icons/verification/verification.png');
export const verification2x = require('./icons/verification/verification@2x.png');
export const verification3x = require('./icons/verification/verification@3x.png');

export const facebook1x = require('./icons/facebook/facebook.png');
export const facebook2x = require('./icons/facebook/facebook@2x.png');
export const facebook3x = require('./icons/facebook/facebook@3x.png');

export const youtube1x = require('./icons/youtube/youtube.png');
export const youtube2x = require('./icons/youtube/youtube@2x.png');
export const youtube3x = require('./icons/youtube/youtube@3x.png');

export const instagram1x = require('./icons/instagram/instagram.png');
export const instagram2x = require('./icons/instagram/instagram@2x.png');
export const instagram3x = require('./icons/instagram/instagram@3x.png');

export const avatar = require('./images/avatar/avatar.png');
export const creator = require('./images/creator/creator.png');
export const profile = require('./images/profile/profile.png');

export const collab1 = require('./collabrations/collab1.png');
export const collab2 = require('./collabrations/collab2.png');
export const collab3 = require('./collabrations/collab3.png');
export const collab4 = require('./collabrations/collab4.png');
export const collab5 = require('./collabrations/collab5.png');
export const collab6 = require('./collabrations/collab6.png');

export const applogo1x = require('./images/applogo/applogo.png');
export const applogo2x = require('./images/applogo/applogo@2x.png');
export const applogo3x = require('./images/applogo/applogo@3x.png');

export const d_applogo1x = require('./dashboardIcons/applogo/applogo.png');
export const d_applogo2x = require('./dashboardIcons/applogo/applogo@2x.png');
export const d_applogo3x = require('./dashboardIcons/applogo/applogo@3x.png');

export const d_chat1x = require('./dashboardIcons/chat/chat.png');
export const d_chat2x = require('./dashboardIcons/chat/chat@2x.png');
export const d_chat3x = require('./dashboardIcons/chat/chat@3x.png');

export const d_financial1x = require('./dashboardIcons/financial/financial.png');
export const d_financial2x = require('./dashboardIcons/financial/financial@2x.png');
export const d_financial3x = require('./dashboardIcons/financial/financial@3x.png');

export const d_dashboard1x = require('./dashboardIcons/dashboard/dashboard.png');
export const d_dashboard2x = require('./dashboardIcons/dashboard/dashboard@2x.png');
export const d_dashboard3x = require('./dashboardIcons/dashboard/dashboard@3x.png');

export const d_facebook1x = require('./dashboardIcons/facebook/facebook.png');
export const d_facebook2x = require('./dashboardIcons/facebook/facebook@2x.png');
export const d_facebook3x = require('./dashboardIcons/facebook/facebook@3x.png');

export const d_instagram1x = require('./dashboardIcons/instagram/instagram.png');
export const d_instagram2x = require('./dashboardIcons/instagram/instagram@2x.png');
export const d_instagram3x = require('./dashboardIcons/instagram/instagram@3x.png');

export const d_link1x = require('./dashboardIcons/link/link.png');
export const d_link2x = require('./dashboardIcons/link/link@2x.png');
export const d_link3x = require('./dashboardIcons/link/link@3x.png');

export const d_tiktok1x = require('./dashboardIcons/tiktok/tiktok.png');
export const d_tiktok2x = require('./dashboardIcons/tiktok/tiktok@2x.png');
export const d_tiktok3x = require('./dashboardIcons/tiktok/tiktok@3x.png');

export const d_twitch1x = require('./dashboardIcons/twitch/twitch.png');
export const d_twitch2x = require('./dashboardIcons/twitch/twitch@2x.png');
export const d_twitch3x = require('./dashboardIcons/twitch/twitch@3x.png');

export const d_x1x = require('./dashboardIcons/x/x.png');
export const d_x2x = require('./dashboardIcons/x/x@2x.png');
export const d_x3x = require('./dashboardIcons/x/x@3x.png');

export const d_youtube1x = require('./dashboardIcons/youtube/youtube.png');
export const d_youtube2x = require('./dashboardIcons/youtube/youtube@2x.png');
export const d_youtube3x = require('./dashboardIcons/youtube/youtube@3x.png');

export const d_settings1x = require('./dashboardIcons/settings/settings.png');
export const d_settings2x = require('./dashboardIcons/settings/settings@2x.png');
export const d_settings3x = require('./dashboardIcons/settings/settings@3x.png');

export const facebookOfficial = require('./icons/officialIcons/facebookOfficial.png');
export const instagramOfficial = require('./icons/officialIcons/instagramOfficial.png');
export const tiktokOfficial = require('./icons/officialIcons/tiktokOfficial.png');
export const xOfficial = require('./icons/officialIcons/xOfficial.png');
export const twitchOfficial = require('./icons/officialIcons/twitchOfficial.png');
