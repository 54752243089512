import { Box, IconButton, Typography } from '@mui/material';
import React, { FC } from 'react';
import { useStyles } from './styles';
import { ArrowBack } from '@material-ui/icons';
import { FINANCE_TABS } from 'utils';
import { Colors } from 'global-styles';

interface Props {
  setSelectedTab: (val: string) => void;
}

export const FinanceWithdraw: FC<Props> = ({ setSelectedTab }) => {
  const classes = useStyles();
  return (
    <Box className={classes.mainContainer}>
      <Box className={classes.headerRowButton}>
        <IconButton
          className={classes.iconBtn}
          onClick={() => setSelectedTab(FINANCE_TABS.SUMMARY)}>
          <ArrowBack style={{ color: Colors.white }} />
        </IconButton>
        <Typography variant="h6">Withdraw</Typography>
      </Box>
    </Box>
  );
};
