import { Box, Typography } from '@mui/material';
import { Colors } from 'global-styles';
import React from 'react';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from 'recharts';
import { TooltipProps } from 'recharts';
import { useInsightsChartStyles } from './styles';

const formatYAxisTick = (tick: number): string => {
  if (tick >= 1000) {
    return `${(tick / 1000).toFixed(0)}k`;
  }
  return `${tick}`;
};

const data = [
  { name: '12-18', age: 10 },
  { name: '19-27', age: 27 },
  { name: '28-32', age: 32 },
  { name: '33-42', age: 43 },
  { name: '43-56', age: 45 },
];

const CustomTooltip: React.FC<TooltipProps<any, any>> = ({
  payload,
  label,
}) => {
  if (!payload || !payload.length) return null;

  return (
    <Box
      className='custom-tooltip'
      style={{
        backgroundColor: Colors.primary,
        border: '0px',
        borderRadius: '8px',
        padding: '10px',
        color: Colors.white,
      }}
    >
      <Typography>{`${label}`}</Typography>
      <Typography>{`Age: ${payload[0].value}`}</Typography>
    </Box>
  );
};

export const AgeChart = () => {
  const classes = useInsightsChartStyles();
  return (
    <Box className={classes.mainContainer}>
      <Box className={classes.labelRow}>
        <Typography
          variant='h6'
          className={classes.ageHeading}
        >
          Age
        </Typography>
      </Box>
      <ResponsiveContainer
        width='100%'
        height={233}
      >
        <BarChart data={data}>
          <CartesianGrid
            stroke={Colors.darkGrey}
            strokeDasharray='3 3'
            vertical={false}
          />
          <XAxis
            dataKey='name'
            stroke='#8884d8'
            tick={{ fill: '#8884d8' }}
            tickLine={{ stroke: '#8884d8' }}
            axisLine={{ stroke: '#8884d8' }}
          />
          <YAxis
            stroke='#8884d8'
            tick={{ fill: '#8884d8' }}
            tickLine={{ stroke: '#8884d8' }}
            axisLine={{ stroke: '#8884d8' }}
            tickFormatter={formatYAxisTick}
          />
          <Tooltip content={<CustomTooltip />} />
          <Bar
            dataKey='age'
            fill={Colors.gradientSecondary}
            barSize={7}
            radius={[10, 10, 0, 0]}
          />
        </BarChart>
      </ResponsiveContainer>
    </Box>
  );
};
