import { BaseButton } from 'components/base-button';
import React, { FC } from 'react';
import { useStyles } from './styles';
import { Box } from '@mui/material';

interface Props {
  onClick?: () => void;
  logo: string;
  heading: string;
}
export const SocialButton: FC<Props> = ({ onClick, logo, heading }) => {
  const classes = useStyles();
  return (
    <BaseButton
      className={classes.socialButton}
      onClick={onClick}
      startIcon={
        <Box className={classes.socialLogoView}>
          <img
            src={logo}
            alt='Google Icon'
            className={classes.socialIcon}
          />
        </Box>
      }
    >
      {heading}
    </BaseButton>
  );
};
