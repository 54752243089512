import { Box, Grid } from '@mui/material';
import React, { FC } from 'react';
import {
  AgeChart,
  CustomLinearChart,
  CustomPieChart,
  GrowthChart,
  InfoCard,
} from 'components';
import { useStyles } from './styles';
import {
  followers1x,
  followers2x,
  followers3x,
  views1x,
  views2x,
  views3x,
} from 'assets';
import {
  FacebookProfileCard,
  InstagramProfileCard,
  TikTokProfileCard,
  TwitchProfileCard,
  TwitterProfileCard,
  YoutubeProfileCard,
} from '../social-profile-cards';

interface Props {
  platformName?: string;
}
export const SocialProfileDashboard: FC<Props> = ({ platformName }) => {
  const classes = useStyles();

  return (
    <Box className={classes.mainContainer}>
      <Grid container spacing={3}>
        <Grid item xs={12} md={8}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={4}>
              <Box className={classes.gridItem}>
                <InfoCard
                  image={[followers1x, followers2x, followers3x]}
                  heading="Followers"
                  count="40k"
                  percentage="+1.29%"
                  duration="+8.4k this week"
                />
              </Box>
            </Grid>
            <Grid item xs={12} sm={4}>
              <Box className={classes.gridItem}>
                <InfoCard
                  image={[views1x, views2x, views3x]}
                  heading="Views"
                  count="40k"
                  percentage="+1.29%"
                  duration="+8.4k this week"
                />
              </Box>
            </Grid>
            <Grid item xs={12} sm={4}>
              <Box className={classes.gridItem}>
                <InfoCard
                  image={[views1x, views2x, views3x]}
                  heading="Shares"
                  count="40k"
                  percentage="+1.29%"
                  duration="+8.4k this week"
                />
              </Box>
            </Grid>
          </Grid>
          <Box mt={3}>
            <GrowthChart />
          </Box>
        </Grid>
        <Grid item xs={12} md={4}>
          {platformName === 'Facebook' && <FacebookProfileCard />}
          {platformName === 'Instagram' && <InstagramProfileCard />}
          {platformName === 'TikTok' && <TikTokProfileCard />}
          {platformName === 'X' && <TwitterProfileCard />}
          {platformName === 'Twitch' && <TwitchProfileCard />}
          {platformName === 'Youtube' && <YoutubeProfileCard />}
        </Grid>
      </Grid>
      <Grid container spacing={3} marginTop={1}>
        <Grid item xs={12} md={4}>
          <CustomPieChart />
        </Grid>
        <Grid item xs={12} md={4}>
          <AgeChart />
        </Grid>
        <Grid item xs={12} md={4}>
          <Box display="flex" flexDirection="column" height="100%">
            <CustomLinearChart />
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};
