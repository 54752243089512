import { makeStyles } from "@mui/styles";
import { Theme } from "@mui/material";
import { Colors } from "global-styles";

export const useStyles = makeStyles((theme: Theme) => ({
  mainContainer: {
    display: "flex",
    backgroundColor: Colors.glass,
    padding: "10px !important",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-around",
    width: "100%",
    zIndex: 1,
  },
  iconBtn:{
    backgroundColor: Colors.lightGlass,
    color:Colors.white,
    "&:hover": {
      backgroundColor: Colors.white, 
      color:Colors.black
    },
  }
}));
