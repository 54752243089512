import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  alertMessage: '',
  alertType: '',
  isAlertVisible: false,
};
export const alertSlice = createSlice({
  name: 'alertSlice',
  initialState,
  reducers: {
    showAlert: (state, action) => {
      const { alertMessage, alertType, isAlertVisible } = action.payload;
      state.isAlertVisible = isAlertVisible;
      state.alertType = alertType;
      state.alertMessage = alertMessage;
    },
    resetAlert: (state) => {
      Object.assign(state, initialState);
    },
  },
});
export const { showAlert, resetAlert } = alertSlice.actions;
export default alertSlice.reducer;
