import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';
import { Colors } from 'global-styles';

export const useStyles = makeStyles((theme: Theme) => ({
  inputStyles: {
    borderRadius: '30px',
    color: 'white',
    fontSize: '15px',
    fontWeight: '500',
    height: '70%',
    width: '60%',
    paddingLeft: '20px',
    '& fieldset': {
      borderColor: Colors.lightWhite,
    },
    '&:hover fieldset': {
      borderColor: Colors.black,
    },
    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderColor: Colors.white,
    },
    '&:hover .MuiOutlinedInput-notchedOutline': {
      borderColor: Colors.white,
    },
  },
}));
