import { makeStyles } from "@mui/styles";
import { Theme } from "@mui/material";
import { Colors } from "global-styles";

export const useStyles = makeStyles((theme: Theme) => ({
  mainContainer: {
    display: "flex",
    height: "380px",
    background: "transparent",
    flexDirection: "column",
    padding: "15px",
    justifyContent: "space-between",
    borderRadius: "15px",
    border: `1px solid ${Colors.lightWhite}`,
    alignItems: "center",
    userSelect: "none",
    transition: "transform 0.3s ease-in-out",
    WebkitTransition:
      "transform 0.3s ease-in-out",
    "&:hover": {
      cursor: "pointer",
      transform: "scale(1.05)",
    },
  },
  nameText: { fontWeight: "bold", fontSize: "18px" },
  bioText: { fontSize: "14px", fontWeight: "500", textAlign: "center" },
  headerContainer: {
    alignItems: "center",
    justifyContent: "center",
    display: "flex",
    flexDirection: "row",
    gap: 15,
  },
  socialRow: {
    alignItems: "center",
    justifyContent: "center",
    display: "flex",
    flexDirection: "row",
    gap: 10,
  },
}));
