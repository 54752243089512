import React from 'react';
import { Box, Typography, LinearProgress } from '@mui/material';
import { styled } from '@mui/system';
import { useLinearChartStyles } from './styles';
import { Colors } from 'global-styles';

interface CountryData {
  country: string;
  value: number;
}

const data: CountryData[] = [
  { country: 'Pakistan', value: 80 },
  { country: 'India', value: 60 },
  { country: 'China', value: 70 },
];

const CustomLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 8,
  borderRadius: 5,
  '& .MuiLinearProgress-bar': {
    borderRadius: 5,
    background: `linear-gradient(to right, ${Colors.gradientPrimary}, ${Colors.gradientSecondary})`,
  },
  backgroundColor: '#333',
}));

export const CustomLinearChart: React.FC = () => {
  const classes = useLinearChartStyles();
  return (
    <Box className={classes.mainContainer}>
      <Typography
        variant='h6'
        gutterBottom
      >
        Country/Region
      </Typography>
      {data.map((item, index) => (
        <Box
          key={index}
          className={classes.innerContainer}
        >
          <Box className={classes.barContainer}>
            <Typography variant='body2'>{item.country}</Typography>
            <CustomLinearProgress
              variant='determinate'
              value={item.value}
            />
          </Box>
          <Typography
            variant='body2'
            sx={{ textAlign: 'right' }}
          >{`${item.value}k`}</Typography>
        </Box>
      ))}
    </Box>
  );
};
