import React from "react";
import { Box } from "@mui/material";
import {
  facebookOfficial,
  google3x,
  instagramOfficial,
  tiktokOfficial,
  twitchOfficial,
  xOfficial,
} from "assets";
import { SocialButton } from "components";
import { useStyles } from "./styles";

export const LinkSocialPlatforms = () => {
  const classes = useStyles();
  return (
    <Box>
      <Box className={classes.buttonColumn}>
        <SocialButton
          heading="Continue with Facebook"
          logo={facebookOfficial}
        />
        <SocialButton
          heading="Continue with Instagram"
          logo={instagramOfficial}
        />
        <SocialButton heading="Continue with TikTok" logo={tiktokOfficial} />
        <SocialButton heading="Continue with X" logo={xOfficial} />
        <SocialButton heading="Continue with Twitch" logo={twitchOfficial} />
        <SocialButton heading="Continue with Google" logo={google3x} />
      </Box>
    </Box>
  );
};
