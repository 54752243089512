import { Box, Typography } from '@mui/material';
import React, { FC } from 'react';
import { useStyles } from './styles';
import {
  facebook1x,
  facebook2x,
  facebook3x,
  instagram1x,
  instagram2x,
  instagram3x,
  youtube1x,
  youtube2x,
  youtube3x,
} from 'assets';

interface Props {}
export const SocialCount: FC<Props> = () => {
  const classes = useStyles();
  return (
    <Box className={classes.socialRow}>
      <Typography>12k</Typography>
      <img
        src={facebook1x}
        srcSet={`${facebook1x} 1x, ${facebook2x} 2x, ${facebook3x} 3x`}
        alt='Descriptive text'
      />
      <Typography>1.12M</Typography>
      <img
        src={instagram1x}
        srcSet={`${instagram1x} 1x, ${instagram2x} 2x, ${instagram3x} 3x`}
        alt='Descriptive text'
      />
      <Typography>998</Typography>
      <img
        src={youtube1x}
        srcSet={`${youtube1x} 1x, ${youtube2x} 2x, ${youtube3x} 3x`}
        alt='Descriptive text'
      />
    </Box>
  );
};
