import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';
import { Colors } from 'global-styles';

export const useProfileCardStyles = makeStyles((theme: Theme) => ({
  mainContainer: {
    display: 'flex',
    color: Colors.white,
    flexDirection: 'column',
    justifyContent: 'space-between',
    zIndex: 1,
    gap: 27.5,
    background: Colors.primaryGlass,
    borderRadius: '10px',
    padding: '20px !important',
    height: '93.4%',
  },
  nameContainer: {
    marginTop: 20,
  },
  rowView: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-around',
  },
  columnView: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  overViewContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    gap: 10,
  },
  rowOverView: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  spacer: {
    alignSelf: 'center',
    display: 'flex',
    height: '1px',
    width: '100%',
    background: Colors.darkGrey,
  },
}));
