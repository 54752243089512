import {
  createAsyncThunk,
  createSelector,
  createSlice,
} from '@reduxjs/toolkit';
import { RootState } from 'app-redux/store';

const initialState = {
  isLoading: false,
  profiledData: null,
};

export const getProfileData = createAsyncThunk(
  'profileSlice/getProfileData',
  async (input, thunkAPI) => {
    try {
    } catch (error) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

export const profileSlice = createSlice({
  name: 'profileSlice',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getProfileData.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getProfileData.fulfilled, (state) => {
        state.isLoading = false;
      })
      .addCase(getProfileData.rejected, (state) => {
        state.isLoading = false;
      });
  },
});

const selectProfileState = (state: RootState) => state.profileSlice;

const selectIsLoadingProfile = createSelector(
  selectProfileState,
  (state) => state.isLoading
);
const selectProfiledData = createSelector(
  selectProfileState,
  (state) => state.profiledData
);
export { selectProfiledData, selectIsLoadingProfile };

export default profileSlice.reducer;
