import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';
import { Colors } from 'global-styles';

export const useBalanceBoxStyles = makeStyles((theme: Theme) => ({
  mainContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    backgroundColor: 'transparent',
    padding: '20px',
    borderRadius: '8px',
    border: `1px solid ${Colors.white}`,
  },
  balanceItem: {
    textAlign: 'center',
    borderRight: `1px solid ${Colors.white}`,
    '&:last-child': {
      borderRight: 'none',
    },
  },
  labelText: {
    color: Colors.white,
    marginBottom: '5px',
  },
  amountText: {
    color: Colors.white,
    fontWeight: 'bold',
  },
}));

export const useEarningLogStyles = makeStyles((theme: Theme) => ({
  mainContainer: {
    backgroundColor: 'transparent',
    overflow: 'hidden',
    '& .MuiTable-root': {
      borderCollapse: 'separate',
    },
  },
  tableInnerContainer: {
    border: `1px solid ${Colors.lightGlass}`,
  },
  headerView: {
    backgroundColor: Colors.primaryGlass,
  },
  textStyle: {
    color: `${Colors.white} !important`,
    borderColor: `${Colors.lightGlass} !important`,
  },
}));
