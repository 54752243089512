import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';
import { Colors } from 'global-styles';

export const useStyles = makeStyles((theme: Theme) => ({
  mainContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  headerRowButton: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    gap: 10,
  },
  iconBtn: {
    border: `1px Solid ${Colors.lightGlass}`,
    '&:hover': {
      border: `1px Solid ${Colors.white}`,
    },
  },
}));
