import { Avatar, Box, Typography } from '@mui/material';
import React, { FC } from 'react';
import { useStyles } from './styles';
import { avatar } from 'assets';
import { BaseButton } from 'components';

interface Props {
  onSaveChanges?: () => void;
}
export const OnBoardingHeader: FC<Props> = ({ onSaveChanges }) => {
  const classes = useStyles();
  return (
    <Box className={classes.mainContainer}>
      <Box className={classes.innerLeftContainer}>
        <Avatar alt="avatar" src={avatar} sx={{ width: 100, height: 100 }} />
        <Box className={classes.columnView}>
          <Typography variant="h6">Hazel Kaya</Typography>
          <Typography variant="body2" className={classes.emailText}>
            HazelKaya@gmail.com
          </Typography>
        </Box>
      </Box>
      <BaseButton
        onClick={onSaveChanges}
        secondary
        width={150}
        className={classes.editBtn}>
        Save Changes
      </BaseButton>
    </Box>
  );
};
