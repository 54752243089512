import { Box, Tab, Tabs, Typography } from '@mui/material';
import React, { FC, useState } from 'react';
import { useStyles } from './styles';
import { BaseButton } from 'components';
import { FINANCE_TABS } from 'utils';
import { BalanceBox } from './components/balance-box';
import { Colors } from 'global-styles';
import { EarningLogTable } from './components/earning-logs';

interface Props {
  setSelectedTab: (val: string) => void;
}

export const FinanceSummary: FC<Props> = ({ setSelectedTab }) => {
  const classes = useStyles();
  const [value, setValue] = useState<number>(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <Box className={classes.mainContainer}>
      <Box className={classes.headerRowButton}>
        <Typography variant="h6">Summary</Typography>
        <Box className={classes.buttonContainer}>
          <BaseButton
            className={classes.btn1}
            onClick={() => setSelectedTab(FINANCE_TABS.WITHDRAW)}>
            Withdraw
          </BaseButton>
          <BaseButton
            className={classes.btn2}
            onClick={() => setSelectedTab(FINANCE_TABS.DEPOSIT)}
            secondary>
            Deposit
          </BaseButton>
        </Box>
      </Box>
      <BalanceBox />
      <Box className={classes.tabsContainer}>
        <Tabs
          value={value}
          onChange={handleChange}
          className={classes.tabs}
          TabIndicatorProps={{
            sx: {
              height: '3px',
              background: `linear-gradient(to right, ${Colors.gradientPrimary}, ${Colors.gradientSecondary}) no-repeat`,
            },
          }}
          textColor="inherit"
          aria-label="custom tabs">
          <Tab label="Overview" className={classes.tab} />
          <Tab label="Transaction History" className={classes.tab} />
        </Tabs>
        <Box className={classes.content}>
          {value === 0 && (
            <Box className={classes.insideTab}>
              <BalanceBox />
              <EarningLogTable />
            </Box>
          )}
          {value === 1 && <BalanceBox />}
        </Box>
      </Box>
    </Box>
  );
};
