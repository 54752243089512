import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';
import { Colors } from 'global-styles';

export const useStyles = makeStyles((theme: Theme) => ({
  mainContainer: {
    display: 'flex',
    backgroundColor: Colors.primaryGlass,
    padding: '10px',
    color: Colors.white,
    flexDirection: 'row',
    alignItems: 'center',
    zIndex: 1,
  },
  rowView: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'row',
    alignItems: 'center',
    gap: 10,
  },
  iconStyles: {
    flex: 0.03,
    marginTop: 5,
  },
  options: {},
}));
