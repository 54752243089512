import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';
import { Colors } from 'global-styles';

export const useStyles = makeStyles((theme: Theme) => ({
  mainContainer: {
    display: 'flex',
    color: Colors.white,
    zIndex: 1,
  },
  leftContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: 20,
    justifyContent: 'center',
    height: '100%',
  },
  rightContainer: {
    display: 'flex',
    height: '100%',
  },
  imageStyle: {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
  },
  nameText: {
    fontWeight: 'bold',
    fontSize: '30px',
    textAlign: 'left',
  },
  bioText: {
    fontSize: '16px',
    fontWeight: '500',
    textAlign: 'left',
    userSelect: 'none',
    pointerEvents: 'none',
  },
  aboutText: { fontWeight: 'bold', fontSize: '20px', textAlign: 'left' },
  tagText: { fontSize: '13px', textAlign: 'center' },
  rowContainer: {
    alignItems: 'center',
    justifyContent: 'flex-start',
    display: 'flex',
    flexDirection: 'row',
    gap: 15,
    flexWrap: 'wrap',
  },
  tagView: {
    padding: 6,
    borderRadius: 8,
    background: Colors.primaryGlass,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  messageBtn: {
    background: `linear-gradient(to right, ${Colors.gradientPrimary}, ${Colors.gradientSecondary}) no-repeat`,
    width: 150,
  },
}));
