import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';
import { Colors } from 'global-styles';

export const useStyles = makeStyles((theme: Theme) => ({
  mainContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: 20,
  },
  headerRowButton: {
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.up('sm')]: {
      flexDirection: 'row',
      justifyContent: 'space-between',
    },
    alignItems: 'center',
    width: '100%',
  },
  buttonContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(1),
    [theme.breakpoints.up('sm')]: {
      flexDirection: 'row',
      gap: theme.spacing(2),
    },
  },
  btn1: {
    width: 120,
    background: `linear-gradient(to right, ${Colors.gradientPrimary}, ${Colors.gradientSecondary}) no-repeat`,
  },
  btn2: {
    width: 120,
    background: 'transparent',
  },
  tabsContainer: {
    width: '100%',
    margin: 'auto',
    borderRadius: '8px',
    overflow: 'hidden',
  },
  tabs: {
    backgroundColor: 'transparent',
    borderBottom: `1px solid ${Colors.lightGlass}`,
  },
  tab: {
    fontWeight: 'bold',
    textTransform: 'none',
    color: Colors.lightGrey,
    '&.Mui-selected': {
      background: `linear-gradient(to right, ${Colors.gradientPrimary}, ${Colors.gradientSecondary})`,
      '-webkit-background-clip': 'text',
      '-webkit-text-fill-color': 'transparent',
      backgroundClip: 'text',
      textFillColor: 'transparent',
    },
  },
  content: {
    paddingTop: '20px !important',
    backgroundColor: 'transparent',
    color: '#ffffff',
  },
  insideTab: {
    flexDirection: 'column',
    gap: 20,
    display: 'flex',
  },
}));
