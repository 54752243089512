import React from 'react';
import { createTheme } from '@mui/material';
import { Colors } from './colors';
declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    body3: true;
    signupQuestion: true;
  }
}

const theme = createTheme({
  spacing: (factor: number) => `${0.25 * factor}rem`,
  typography: {
    fontFamily: 'Plus Jakarta Sans, sans-serif',
    fontSize: 16,
  },
  components: {
    MuiTypography: {
      styleOverrides: {
        root: {
          fontFamily: 'Plus Jakarta Sans, sans-serif',
          color: Colors.white,
          defaultVariant: 'body1',
        },
      },
    },
  },
});
theme.typography.body1 = {
  fontSize: 12,
  '@media (min-width:400px)': {
    fontSize: 14,
  },

  [theme.breakpoints.up('md')]: {
    fontSize: 16,
  },
};

theme.typography.h6 = {
  '@media (min-width:400px)': {
    fontSize: 14,
  },

  [theme.breakpoints.up('md')]: {
    fontSize: 20,
  },
};
theme.typography.body3 = {
  [theme.breakpoints.up('sm')]: {
    fontSize: 8, // Small screens (sm)
  },
  [theme.breakpoints.up('md')]: {
    fontSize: 10, // Medium screens (md)
  },
  [theme.breakpoints.up('lg')]: {
    fontSize: 12, // Large screens (lg)
  },
  [theme.breakpoints.up('xl')]: {
    fontSize: 12, // Extra-large screens (xl)
  },
};
theme.typography.signupQuestion = {
  [theme.breakpoints.up('sm')]: {
    fontSize: 10,
  },
  [theme.breakpoints.up('md')]: {
    fontSize: 12,
  },
  [theme.breakpoints.up('lg')]: {
    fontSize: 14,
  },
  [theme.breakpoints.up('xl')]: {
    fontSize: 15,
  },
};
theme.typography.h5 = {
  '@media (min-width:400px)': {
    fontSize: 16,
    fontWeight: '500',
  },

  [theme.breakpoints.up('md')]: {
    fontSize: 28,
    fontWeight: '500',
  },
};

theme.spacing(2);
export { theme };

declare module '@mui/material' {
  interface TypographyPropsVariantOverrides {
    body3: true;
    signupQuestion: true;
  }

  interface TypographyClasses {
    body3: string;
    signupQuestion: string;
  }
}
declare module '@mui/material/styles' {
  // allow configuration using `createTheme`
  interface TypographyVariantsOptions {
    body3?: React.CSSProperties;
    signupQuestion?: React.CSSProperties;
  }

  interface TypographyVariants {
    body3: React.CSSProperties;
    signupQuestion: React.CSSProperties;
  }
}
