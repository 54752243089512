import { Avatar, Box, Typography } from "@mui/material";
import React from "react";
import { avatar } from "assets";
import { Colors } from "global-styles";
import { useProfileCardStyles } from "./styles";
import { BaseButton } from "components";

export const TwitchProfileCard = () => {
  const classes = useProfileCardStyles();
  return (
    <Box className={classes.mainContainer}>
      <Box className={classes.rowOverView}>
        <Avatar
          alt="Remy Sharp"
          src={avatar}
          sx={{ width: 100, height: 100 }}
        />
        <BaseButton width={100} style={{background: '#9146FF'}}>Twitch</BaseButton>
      </Box>
      <Box>
        <Typography variant="h6">Hazel kayaes</Typography>
        <Typography variant="body2" color={Colors.lightGrey}>
          @hazelkayaes. 19
        </Typography>
      </Box>
      <Typography variant="body1" color={Colors.lightGrey}>
        Lorem Ipsum is simply dummy text of the printing and typesetting
        industry. Lorem Ipsum has been the industry's standard dummy text ever
        since the 1500s, when an{" "}
      </Typography>
      <Box className={classes.rowView}>
        <Box className={classes.columnView}>
          <Typography variant="h6" textAlign={"center"}>
            40k
          </Typography>
          <Typography variant="body1" textAlign={"center"}>
            Following
          </Typography>
        </Box>
        <Box className={classes.columnView}>
          <Typography variant="h6" textAlign={"center"}>
            1.1M
          </Typography>
          <Typography variant="body1" textAlign={"center"}>
            Followers
          </Typography>
        </Box>
        <Box className={classes.columnView}>
          <Typography variant="h6" textAlign={"center"}>
            223
          </Typography>
          <Typography variant="body1" textAlign={"center"}>
            Posts
          </Typography>
        </Box>
      </Box>
      <Box className={classes.spacer} />
      <Box className={classes.overViewContainer}>
        <Typography variant="h6" textAlign={"left"}>
          Followers Overview
        </Typography>
        <Box className={classes.rowOverView}>
          <Typography variant="body2">Asia</Typography>
          <Typography variant="body1">35k</Typography>
        </Box>
        <Box className={classes.rowOverView}>
          <Typography>Europe</Typography>
          <Typography>35k</Typography>
        </Box>
        <Box className={classes.rowOverView}>
          <Typography>Africa</Typography>
          <Typography>35k</Typography>
        </Box>
        <Box className={classes.rowOverView}>
          <Typography>Other Continent</Typography>
          <Typography>35k</Typography>
        </Box>
      </Box>
    </Box>
  );
};
