import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { authInputType } from 'types';
import {
  signUp,
  signOut,
  signIn,
  confirmSignUp,
  fetchUserAttributes,
  resendSignUpCode,
} from 'aws-amplify/auth';

const initialState = {
  isLogging: false,
  isLogin: false,
  accessStatus: null,
  registerData: null,
  user: null,
  userInfo: null,
  error: null,
  token: null,
};

export const loginUser = createAsyncThunk(
  'authSlice/loginUser',
  async (credentials: authInputType, thunkAPI) => {
    try {
      const { email, password } = credentials;
      const signInResponse = await signIn({ username: email, password });
      if (signInResponse?.isSignedIn) {
        const user = await fetchUserAttributes();
        return { signInResponse, user };
      } else {
        thunkAPI.dispatch(reSendVerificationCode(email));
        return { signInResponse, registerData: credentials };
      }
    } catch (error) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

export const registerUser = createAsyncThunk(
  'authSlice/registerUser',
  async (input: authInputType, thunkAPI) => {
    try {
      const { email, password, fullName } = input;
      const signUpResponse = await signUp({
        username: email,
        password,
        options: {
          userAttributes: {
            email: email,
            name: fullName,
          },
        },
      });
      return { signUpResponse, input };
    } catch (error) {
      thunkAPI.dispatch(logoutUser());
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

export const verifyEmail = createAsyncThunk(
  'authSlice/verifyEmail',
  async (credentials: authInputType, thunkAPI) => {
    try {
      const { email, confirmationCode } = credentials;
      const { isSignUpComplete } = await confirmSignUp({
        username: email,
        confirmationCode,
      });
      return isSignUpComplete;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

export const reSendVerificationCode = createAsyncThunk(
  'authSlice/reSendVerificationCode',
  async (username: string, thunkAPI) => {
    try {
      await resendSignUpCode({ username });
      return;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

export const logoutUser = createAsyncThunk(
  'authSlice/logoutUser',
  async (_, thunkAPI) => {
    try {
      await signOut();
      localStorage.removeItem('accessToken');
      thunkAPI.dispatch({ type: 'logout/LOGOUT' });
    } catch (error) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

export const logoutUnAuthenticatedUser = createAsyncThunk(
  'authSlice/logoutUnAuthenticatedUser',
  async (_, thunkAPI) => {
    try {
      localStorage.removeItem('accessToken');
      thunkAPI.dispatch({ type: 'logout/LOGOUT' });
    } catch (error) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

export const authSlice = createSlice({
  name: 'authSlice',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(loginUser.pending, (state) => {
        state.isLogging = true;
        state.error = null;
      })
      .addCase(loginUser.fulfilled, (state, action) => {
        state.isLogging = false;
        state.isLogin = action.payload.signInResponse.isSignedIn;
        state.user = action.payload.user;
        state.accessStatus = action.payload.signInResponse.nextStep.signInStep;
        state.registerData = action.payload.registerData;
      })
      .addCase(loginUser.rejected, (state, action) => {
        state.isLogging = false;
        state.error = action.payload;
      })
      .addCase(registerUser.pending, (state) => {
        state.isLogging = true;
        state.error = null;
      })
      .addCase(registerUser.fulfilled, (state, action) => {
        state.isLogging = false;
        state.accessStatus = action.payload.signUpResponse.nextStep.signUpStep;
        state.registerData = action.payload.input;
      })
      .addCase(registerUser.rejected, (state, action) => {
        state.isLogging = false;
        state.error = action.payload;
        state.accessStatus = null;
        state.registerData = null;
      })
      .addCase(logoutUser.pending, (state) => {
        state.isLogging = true;
        state.error = null;
      })
      .addCase(logoutUser.fulfilled, (state) => {
        Object.assign(state, initialState);
      })
      .addCase(logoutUser.rejected, (state, action) => {
        state.isLogging = false;
        state.error = action.payload;
      })
      .addCase(verifyEmail.pending, (state) => {
        state.isLogging = true;
        state.error = null;
      })
      .addCase(verifyEmail.fulfilled, (state) => {
        state.isLogging = false;
      })
      .addCase(verifyEmail.rejected, (state, action) => {
        state.isLogging = false;
        state.error = action.payload;
      })
      .addCase(reSendVerificationCode.pending, (state) => {
        state.isLogging = true;
        state.error = null;
      })
      .addCase(reSendVerificationCode.fulfilled, (state) => {
        state.isLogging = false;
      })
      .addCase(reSendVerificationCode.rejected, (state, action) => {
        state.isLogging = false;
        state.error = action.payload;
      });
  },
});
export default authSlice.reducer;
