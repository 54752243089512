import { makeStyles } from "@mui/styles";
import { Theme } from "@mui/material";

export const useStyles = makeStyles((theme: Theme) => ({
  mainContainer: {
    overflowY: "auto",
    zIndex: 1,
    "&::-webkit-scrollbar": { display: "none" },
    padding:'50px !important'
  },
  gridItem: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100%",
  },
}));
