import {
  Avatar,
  Box,
  IconButton,
  Menu,
  MenuItem,
  Typography,
} from '@mui/material';
import React, { FC, useState } from 'react';
import { useStyles } from './styles';
import { NotificationAddOutlined } from '@mui/icons-material';
import { ArrowDropDown } from '@material-ui/icons';
import { Colors } from 'global-styles';
import { avatar } from 'assets';
import { useAppDispatch, useAppSelector } from 'hooks';
import { RootState } from 'app-redux/store';
import { logoutUser } from 'app-redux';

interface Props {
  heading?: string;
  subheading?: string;
  icon?: string[];
}

export const DashboardHeader: FC<Props> = ({ heading, subheading, icon }) => {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const { user } = useAppSelector((state: RootState) => state.authSlice);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClose = () => setAnchorEl(null);

  const handleClick = (event) => setAnchorEl(event.currentTarget);

  const handleLogout = () => dispatch(logoutUser());

  return (
    <Box className={classes.mainContainer}>
      {!subheading && (
        <Box className={classes.iconStyles}>
          <img
            src={icon[0]}
            srcSet={`${icon[0]} 1x, ${icon[1]} 2x, ${icon[2]} 3x`}
            alt={`${heading}-icon`}
          />
        </Box>
      )}
      <Box flex={1}>
        <Typography variant="h6">{heading}</Typography>
        {subheading && (
          <Typography variant="body1" color={Colors.darkGrey}>
            {subheading}
          </Typography>
        )}
      </Box>
      <Box className={classes.rowView}>
        <IconButton>
          <NotificationAddOutlined style={{ color: Colors.white }} />
        </IconButton>

        <Avatar alt="Remy Sharp" src={avatar} sx={{ width: 30, height: 30 }} />
        <Typography fontWeight={'600'}>{user?.name}</Typography>
        <IconButton onClick={handleClick}>
          <ArrowDropDown style={{ color: Colors.white }} />
        </IconButton>
        <Menu
          className={classes.options}
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}>
          <MenuItem onClick={handleLogout}>Logout</MenuItem>
        </Menu>
      </Box>
    </Box>
  );
};
