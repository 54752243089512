import { makeStyles } from "@mui/styles";
import { Theme } from "@mui/material";
import { Colors } from "global-styles";

export const useStyles = makeStyles((theme: Theme) => ({
  innerContainer: {
    zIndex: 1,
    background: Colors.glass,
    padding: "20px !important",
    borderRadius: "10px",
    display: "flex",
    flexDirection: "column",
    width: "70%",
    overflowY: "auto",
    "&::-webkit-scrollbar": { display: "none" },
  },
}));
