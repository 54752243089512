import { AlertSnack } from 'components';
import { withAuthenticator } from 'hooks';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import {
  CreatorDashboard,
  CreatorProfile,
  Home,
  OnBoarding,
  SignIn,
  SignUp,
} from 'screens';

const ProtectedRoutes = () => {
  return (
    <Routes>
      <Route path="/*" element={<Home />} />
      <Route path="/home" element={<Home />} />
      <Route path="/creator-dashboard" element={<CreatorDashboard />} />
      <Route path="/creator-profile" element={<CreatorProfile />} />
      <Route path="/onboarding" element={<OnBoarding />} />
    </Routes>
  );
};

const AuthenticationRoutes = () => {
  return (
    <Routes>
      <Route path="/*" element={<Home />} />
      <Route path="/sign-in" element={<SignIn />} />
      <Route path="/sign-up" element={<SignUp />} />
      <Route path="/creator-profile" element={<CreatorProfile />} />
    </Routes>
  );
};

const PagesWithAuth = withAuthenticator(ProtectedRoutes, AuthenticationRoutes);

export const AppRouter = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/*" element={<PagesWithAuth />} />
      </Routes>
      <AlertSnack />
    </BrowserRouter>
  );
};
