import React, { FC, useEffect } from 'react';
import { Snackbar } from '@mui/material';
import { useAppDispatch, useAppSelector } from 'hooks/redux-hooks';
import { RootState } from 'app-redux/store';
import { resetAlert } from 'app-redux';
import Alert from '@mui/material/Alert';

export const AlertSnack: FC = () => {
  const dispatch = useAppDispatch();
  const { alertMessage, alertType, isAlertVisible } = useAppSelector(
    (state: RootState) => state.alertSlice
  );
  const handleClose = () => dispatch(resetAlert());

  useEffect(() => {
    if (isAlertVisible) {
      setTimeout(() => {
        dispatch(resetAlert());
      }, 5000);
    }
  }, [isAlertVisible, dispatch]);

  return (
    <Snackbar
      open={isAlertVisible}
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      autoHideDuration={5000}
      onClose={handleClose}
    >
      <Alert
        onClose={handleClose}
        severity={alertType}
        variant='filled'
        sx={{ width: '100%' }}
      >
        {alertMessage || 'Something went wrong, please try again!'}
      </Alert>
    </Snackbar>
  );
};
