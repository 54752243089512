import { Box, Grid, Typography } from '@mui/material';
import React from 'react';
import { useBalanceBoxStyles } from './styles';

export const BalanceBox = () => {
  const classes = useBalanceBoxStyles();
  return (
    <Grid container className={classes.mainContainer}>
      <Grid item xs={12} md={4} className={classes.balanceItem}>
        <Typography variant="body2" className={classes.labelText}>
          Current Balance
        </Typography>
        <Typography variant="h4" className={classes.amountText}>
          US$2,500.75
        </Typography>
      </Grid>
      <Grid item xs={12} md={4} className={classes.balanceItem}>
        <Typography variant="body2" className={classes.labelText}>
          Pending Payments
        </Typography>
        <Typography variant="h4" className={classes.amountText}>
          US$3,780.75
        </Typography>
      </Grid>
      <Grid item xs={12} md={4} className={classes.balanceItem}>
        <Typography variant="body2" className={classes.labelText}>
          Available Funds
        </Typography>
        <Typography variant="h4" className={classes.amountText}>
          US$1,230.81
        </Typography>
      </Grid>
    </Grid>
  );
};
