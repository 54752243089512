import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';
import { Colors } from 'global-styles';

export const useStyles = makeStyles((theme: Theme) => ({
  socialButton: {
    marginTop: 20,
    marginBottom: 20,
    width: '80%',
    height: '50px',
    borderRadius: '8px',
    alignItems: 'center',
    gap: 10,
    justifyContent: 'center',
    background: `${Colors.black} !important`,
    color: 'white !important',
    textAlign: 'center',
  },
  socialIcon: {
    height: 25,
    width: 25,
  },
  socialLogoView: {
    display: 'flex',
  },
}));
