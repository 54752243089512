import { Grid, Typography } from '@mui/material';
import React, { useReducer, useState } from 'react';
import { useStyles } from './styles';
import { TextInput } from 'components';
import { CreatorHeader } from '../creator-header';
import { profileStateType } from 'types';
import { INITIAL_PROFILE_STATE } from 'utils';

export const CreatorBody = () => {
  const classes = useStyles();
  const [
    {
      fullName,
      fullNameError,
      username,
      usernameError,
      gender,
      genderError,
      country,
      countryError,
      language,
      languageError,
      state,
      stateError,
      isValid,
    },
    setData,
  ] = useReducer(
    (state: profileStateType, newState) => ({ ...state, ...newState }),
    INITIAL_PROFILE_STATE
  );
  const onSaveChanges = () => {};
  return (
    <Grid
      container
      rowSpacing={4}
      columnSpacing={{ xs: 1, sm: 2, md: 3 }}
      zIndex={1}>
      <Grid item xs={12}>
        <CreatorHeader onSaveChanges={onSaveChanges} />
      </Grid>
      <Grid item xs={12} md={6} sm={6}>
        <Typography variant="body1" className={classes.labelStyle}>
          Full Name
        </Typography>
        <TextInput
          type="text"
          name="fullName"
          value={fullName}
          error={fullNameError}
          placeholder="Full Name"
        />
      </Grid>
      <Grid item xs={12} md={6} sm={6}>
        <Typography variant="body1" className={classes.labelStyle}>
          Username
        </Typography>
        <TextInput
          type="text"
          name="username"
          value={username}
          error={usernameError}
          placeholder="Username"
        />
      </Grid>
      <Grid item xs={12} md={6} sm={6}>
        <Typography variant="body1" className={classes.labelStyle}>
          Gender
        </Typography>
        <TextInput
          type="text"
          name="gender"
          value={gender}
          error={genderError}
          placeholder="Gender"
        />
      </Grid>
      <Grid item xs={12} md={6} sm={6}>
        <Typography variant="body1" className={classes.labelStyle}>
          Country
        </Typography>
        <TextInput
          type="text"
          name="country"
          value={country}
          error={countryError}
          placeholder="Country"
        />
      </Grid>
      <Grid item xs={12} md={6} sm={6}>
        <Typography variant="body1" className={classes.labelStyle}>
          Language
        </Typography>
        <TextInput
          type="text"
          name="language"
          value={language}
          error={languageError}
          placeholder="Language"
        />
      </Grid>
      <Grid item xs={12} md={6} sm={6}>
        <Typography variant="body1" className={classes.labelStyle}>
          Province
        </Typography>
        <TextInput
          type="text"
          name="state"
          value={state}
          error={stateError}
          placeholder="Province"
        />
      </Grid>
    </Grid>
  );
};
