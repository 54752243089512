import Box from '@mui/material/Box';
import { styled } from '@mui/system';

export const FullScreenBox = styled(Box)(() => ({
  width: '100%',
  height: '100vh',
  display: 'flex',
  alignItems: 'center',
  overflow: 'hidden',
  position: 'relative',
  boxSizing: 'border-box',
}));
