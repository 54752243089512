import { Box } from '@mui/material';
import React, { useState } from 'react';
import { useStyles } from './styles';
import { FinanceDeposit, FinanceSummary, FinanceWithdraw } from './components';
import { FINANCE_TABS } from 'utils';

export const DashboardFinance = () => {
  const classes = useStyles();
  const [selectedTab, setSelectedTab] = useState<string>(FINANCE_TABS.SUMMARY);

  return (
    <Box className={classes.mainContainer}>
      {selectedTab === FINANCE_TABS.SUMMARY && (
        <FinanceSummary setSelectedTab={setSelectedTab} />
      )}
      {selectedTab === FINANCE_TABS.DEPOSIT && (
        <FinanceDeposit setSelectedTab={setSelectedTab} />
      )}
      {selectedTab === FINANCE_TABS.WITHDRAW && (
        <FinanceWithdraw setSelectedTab={setSelectedTab} />
      )}
    </Box>
  );
};
