import { FullScreenBox, PageBlur } from 'components';
import React from 'react';
import { CreatorBody } from './components';
import { Box } from '@mui/material';
import { useStyles } from './styles';

export const CreatorSettings = () => {
  const classes = useStyles();
  return (
    <FullScreenBox justifyContent={'center'}>
      <Box className={classes.innerContainer}>
        <CreatorBody />
      </Box>
      <PageBlur />
    </FullScreenBox>
  );
};
