import React from 'react';
import { Box, Grid } from '@mui/material';
import { useStyles } from './styles';
import {
  AgeChart,
  CustomBarChart,
  CustomLinearChart,
  CustomPieChart,
} from 'components';

export const ProfileInsights = () => {
  const classes = useStyles();
  return (
    <Grid
      container
      spacing={2}
      className={classes.gridContainer}
      justifyContent="space-between">
      <Grid item xs={12} md={6}>
        <CustomBarChart />
      </Grid>
      <Grid item xs={12} md={6}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={5}>
            <CustomPieChart />
          </Grid>
          <Grid item xs={12} md={7}>
            <AgeChart />
          </Grid>
          <Grid item xs={12} md={12}>
            <Box display="flex" flexDirection="column" height="100%">
              <CustomLinearChart />
            </Box>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
