import React, { FC } from 'react';
import { Box, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { applogo1x, applogo2x, applogo3x } from 'assets';

interface Props {
  size?: string;
}

export const AppNameText: FC<Props> = ({ size }) => {
  const navigate = useNavigate();

  const handlePress = () => {
    navigate('/home');
  };

  return (
    <Box
      onClick={handlePress}
      sx={{
        cursor: 'pointer',
        transition: 'transform 0.3s ease-in-out',
        zIndex: 99,
        '&:hover': {
          transform: 'scale(1.1)',
        },
      }}>
      <img
        src={applogo1x}
        srcSet={`${applogo1x} 1x, ${applogo2x} 2x, ${applogo3x} 3x`}
        alt="App Logo"
        style={{
          width: size ? size : '120px',
          height: 'auto',
        }}
      />
    </Box>
  );
};
