import React from "react";
import { Box, Grid, Typography } from "@mui/material";
import {
  followers1x,
  followers2x,
  followers3x,
  likes1x,
  likes2x,
  likes3x,
  platform1x,
  platform2x,
  platform3x,
  views1x,
  views2x,
  views3x,
} from "assets";
import { InfoCard } from "components";
import { useStyles } from "./styles";

export const ProfileReach = () => {
  const classes = useStyles();
  return (
    <Grid
      container
      spacing={2}
      className={classes.gridContainer}
      justifyContent="center"
    >
      <Grid item xs={12} marginBottom={10} marginLeft={10}>
        <Typography className={classes.reachText}>Overall Reach</Typography>
      </Grid>

      <Grid item xs={12} md={3} sm={2}>
        <Box className={classes.gridItem}>
          <InfoCard
            image={[followers1x, followers2x, followers3x]}
            heading="Followers"
            count="40k"
            percentage="13.90%"
            duration="+8.4k this week"
          />
        </Box>
      </Grid>
      <Grid item xs={12} md={3} sm={2}>
        <Box className={classes.gridItem}>
          <InfoCard
            image={[platform1x, platform2x, platform3x]}
            heading="Platform"
            count="40k"
            percentage="13.90%"
            duration="+8.4k this week"
          />
        </Box>
      </Grid>
      <Grid item xs={12} md={3} sm={2}>
        <Box className={classes.gridItem}>
          <InfoCard
            image={[likes1x, likes2x, likes3x]}
            heading="Total Likes"
            count="40k"
            percentage="13.90%"
            duration="+8.4k this week"
          />
        </Box>
      </Grid>
      <Grid item xs={12} md={3} sm={2}>
        <Box className={classes.gridItem}>
          <InfoCard
            image={[views1x, views2x, views3x]}
            heading="Total Views"
            count="40k"
            percentage="13.90%"
            duration="+8.4k this week"
          />
        </Box>
      </Grid>
    </Grid>
  );
};
