import { Grid, Typography } from '@mui/material';
import React, { useReducer, useState } from 'react';
import { useStyles } from './styles';
import { DropDown, TextInput } from 'components';
import { GENDER, LANGUAGES } from 'utils';
import { OnBoardingHeader } from '../onboarding-header';

export const OnBoardingBody = () => {
  const classes = useStyles();
  const [fullName, setFullName] = useState<string>('');
  const [username, setUsername] = useState<string>('');
  const [gender, setGender] = useState<string>('');
  const [country, setCountry] = useState<string>('');
  const [language, setLanguage] = useState<string>('');
  const [state, setState] = useState<string>('');

  const onSaveChanges = () => {};
  return (
    <Grid
      container
      rowSpacing={4}
      columnSpacing={{ xs: 1, sm: 2, md: 3 }}
      zIndex={1}>
      <Grid item xs={12}>
        <OnBoardingHeader onSaveChanges={onSaveChanges} />
      </Grid>
      <Grid item xs={12} md={6} sm={6}>
        <Typography variant="body1" className={classes.labelStyle}>
          Full Name
        </Typography>
        <TextInput
          type="text"
          name="fullName"
          value={fullName}
          placeholder="Full Name"
          onChange={(e) => setFullName(e.target.value)}
        />
      </Grid>
      <Grid item xs={12} md={6} sm={6}>
        <Typography variant="body1" className={classes.labelStyle}>
          Username
        </Typography>
        <TextInput
          type="text"
          name="username"
          value={username}
          placeholder="Username"
          onChange={(e) => setUsername(e.target.value)}
        />
      </Grid>
      <Grid item xs={12} md={6} sm={6}>
        <Typography variant="body1" className={classes.labelStyle}>
          Gender
        </Typography>
        <DropDown
          list={GENDER}
          placeholder={'Select'}
          setValue={setGender}
          value={gender}
        />
      </Grid>
      <Grid item xs={12} md={6} sm={6}>
        <Typography variant="body1" className={classes.labelStyle}>
          Country
        </Typography>
        <TextInput
          type="text"
          name="country"
          value={country}
          placeholder="Country"
        />
      </Grid>
      <Grid item xs={12} md={6} sm={6}>
        <Typography variant="body1" className={classes.labelStyle}>
          Language
        </Typography>
        <DropDown
          list={LANGUAGES}
          placeholder={'Select'}
          setValue={setLanguage}
          value={language}
        />
      </Grid>
      <Grid item xs={12} md={6} sm={6}>
        <Typography variant="body1" className={classes.labelStyle}>
          State
        </Typography>
        <TextInput type="text" name="state" value={state} placeholder="state" />
      </Grid>
    </Grid>
  );
};
