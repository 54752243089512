import React from 'react';
import { Button } from '@mui/material';
import { ButtonProps } from '@mui/material/Button';
import { Colors } from 'global-styles';
import { FC } from 'react';

interface ButtonTypes extends ButtonProps {
  secondary?: boolean;
  width?: number | string;
  height?: number | string;
  fontSize?: number | string;
}

export const BaseButton: FC<ButtonTypes> = ({
  children,
  secondary = false,
  fontSize,
  height,
  width,
  ...rest
}) => {
  return (
    <Button
      {...rest}
      sx={[
        {
          borderRadius: '5px',
          height: height || 43,
          color: Colors.white,
          border: secondary ? '1px solid' : 'none',
          borderColor: Colors.white,
          width: width || 220,
          backgroundColor: secondary ? 'transparent' : Colors.primary,
          fontSize: fontSize ? fontSize : 15,
          textTransform: 'none',
          fontFamily: 'Plus Jakarta Sans, sans-serif',
          '&:hover': {
            backgroundColor: secondary ? 'none' : Colors.primary + 90, // Background color on hover
          },
        },
      ]}>
      {children}
    </Button>
  );
};
