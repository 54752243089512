import React, { FC } from 'react';
import { Box, Typography } from '@mui/material';
import { Colors } from 'global-styles';

export const AppLoader: FC = () => {
  return (
    <Box sx={styles.mainContainer}>
      <Typography
        align='center'
        style={styles.headingText}
      >
        Kitor AI
      </Typography>
      <Typography
        align='center'
        style={styles.loadingText}
      >
        Loading...
      </Typography>
    </Box>
  );
};

const styles = {
  mainContainer: {
    position: 'relative',
    height: '100vh',
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    overflow: 'hidden',
    gap: 2,
  },
  headingText: {
    fontWeight: '700',
    fontSize: '100px',
    WebkitTextFillColor: 'transparent',
    background: `linear-gradient(to right, ${Colors.gradientPrimary}, ${Colors.gradientSecondary})`,
    WebkitBackgroundClip: 'text',
    backgroundSize: '200% auto',
  },
  loadingText: {
    fontWeight: '500',
    fontSize: '30px',
  },
};
