import { Box, Typography } from '@mui/material';
import React from 'react';
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
  CartesianGrid,
} from 'recharts';
import { useInsightsChartStyles } from './styles';
import { Colors } from 'global-styles';
import { formatYAxisTick } from 'utils';

interface ChartData {
  name: string;
  likes: number;
  reach: number;
  engagement: number;
}

const data: ChartData[] = [
  { name: 'Jan', likes: 4000, reach: 2400, engagement: 2400 },
  { name: 'Feb', likes: 3000, reach: 1398, engagement: 2210 },
  { name: 'Mar', likes: 20000, reach: 4000, engagement: 4000 },
  { name: 'Apr', likes: 2780, reach: 3908, engagement: 2000 },
  { name: 'May', likes: 1890, reach: 4800, engagement: 2181 },
  { name: 'Jun', likes: 2390, reach: 3800, engagement: 2500 },
  { name: 'Jul', likes: 3490, reach: 4300, engagement: 2100 },
  { name: 'Aug', likes: 3490, reach: 4300, engagement: 2100 },
  { name: 'Sep', likes: 3490, reach: 4300, engagement: 2100 },
  { name: 'Oct', likes: 3490, reach: 4300, engagement: 2100 },
  { name: 'Nov', likes: 3490, reach: 4300, engagement: 2100 },
  { name: 'Dec', likes: 3490, reach: 4300, engagement: 2100 },
];

const CustomTooltip = ({ active, payload, label }: any) => {
  if (active && payload && payload.length) {
    return (
      <Box
        style={{
          backgroundColor: Colors.primary,
          border: '0px',
          borderRadius: '8px',
          padding: '10px',
          color: Colors.white,
        }}
      >
        <Typography variant='h6'>{`${label}, 2024`}</Typography>
        <Typography>{`Likes: ${payload[0].value}k`}</Typography>
        <Typography>{`Reach: ${payload[1].value}k`}</Typography>
        <Typography>{`Engagement: ${payload[2].value}k`}</Typography>
      </Box>
    );
  }

  return null;
};

export const GrowthChart: React.FC = () => {
  const classes = useInsightsChartStyles();
  return (
    <Box className={classes.mainContainer}>
      <Box className={classes.labelRow}>
        <Typography
          variant='h6'
          className={classes.ageHeading}
        >
          Profile Growth and Discovery
        </Typography>
      </Box>
      <ResponsiveContainer
        width='100%'
        height={400}
      >
        <LineChart
          data={data}
          margin={{ top: 20, right: 30, left: 20, bottom: 5 }}
        >
          <CartesianGrid
            strokeDasharray='3 3'
            vertical={false}
            stroke={Colors.darkGrey}
          />
          <XAxis
            dataKey='name'
            tick={{ fill: '#8884d8' }}
          />
          <YAxis
            tick={{ fill: '#8884d8' }}
            tickFormatter={formatYAxisTick}
          />
          <Tooltip content={<CustomTooltip />} />
          <Line
            type='monotone'
            dataKey='likes'
            stroke='#8884d8'
            strokeWidth={2}
            dot={false}
          />
          <Line
            type='monotone'
            dataKey='reach'
            stroke='#82ca9d'
            strokeWidth={2}
            dot={false}
          />
          <Line
            type='monotone'
            dataKey='engagement'
            stroke='#c858e8'
            strokeWidth={2}
            dot={false}
          />
        </LineChart>
      </ResponsiveContainer>
    </Box>
  );
};
