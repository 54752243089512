import React from 'react';
import './App.css';
import { Root, theme } from 'global-styles';
import { ThemeProvider } from '@mui/material';
import { AppRouter } from 'routes';
import awsmobile from 'aws-exports';
import { Amplify } from 'aws-amplify';

Amplify.configure(awsmobile);

function App() {
  return (
    <Root>
      <ThemeProvider theme={theme}>
        <AppRouter />
      </ThemeProvider>
    </Root>
  );
}

export default App;
