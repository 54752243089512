import React from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
} from '@mui/material';
import { useEarningLogStyles } from './styles';

interface EarningLog {
  date: string;
  description: string;
  from: string;
  order: string;
  amount: string;
}

const earningLogs: EarningLog[] = [
  {
    date: '12/2/15',
    description: 'Instagram Post',
    from: 'Alice',
    order: 'Z-23Z45',
    amount: 'US$2,23.23',
  },
  {
    date: '12/2/15',
    description: 'Facebook Video',
    from: 'Andrew',
    order: 'F-12312d',
    amount: 'US$8,00.75',
  },
  {
    date: '12/2/15',
    description: 'Instagram Reel',
    from: 'John',
    order: 'D-23E45',
    amount: 'US$2,000.23',
  },
  {
    date: '12/2/15',
    description: 'Tweet',
    from: 'Hame',
    order: 'E-23Z45',
    amount: 'US$2,23.23',
  },
  {
    date: '12/2/15',
    description: 'Instagram Post',
    from: 'Alice',
    order: 'Z-23Z45',
    amount: 'US$2,23.23',
  },
];

export const EarningLogTable = () => {
  const classes = useEarningLogStyles();
  return (
    <TableContainer component={Paper} className={classes.mainContainer}>
      <Typography variant="h6" sx={{ paddingBottom: '20px' }}>
        Earning log
      </Typography>
      <Table className={classes.tableInnerContainer}>
        <TableHead className={classes.headerView}>
          <TableRow>
            <TableCell className={classes.textStyle}>Date</TableCell>
            <TableCell className={classes.textStyle}>Description</TableCell>
            <TableCell className={classes.textStyle}>From</TableCell>
            <TableCell className={classes.textStyle}>Order</TableCell>
            <TableCell className={classes.textStyle}>Amount</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {earningLogs.map((log, index) => (
            <TableRow key={index}>
              <TableCell className={classes.textStyle}>{log.date}</TableCell>
              <TableCell className={classes.textStyle}>
                {log.description}
              </TableCell>
              <TableCell className={classes.textStyle}>{log.from}</TableCell>
              <TableCell className={classes.textStyle}>{log.order}</TableCell>
              <TableCell className={classes.textStyle}>{log.amount}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
