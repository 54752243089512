import React, { FC } from 'react';
import { Box } from '@mui/material';
import { useAppSelector } from 'hooks';
import { RootState } from 'app-redux/store';
import { AppNameText, BackDrop, FullScreenBox, PageBlur } from 'components';
import { ACCESS_STATUS } from 'utils';
import { useStyles } from './styles';
import { UserInfo } from './components/user-info';
import { VerifyCode } from './components/verify-code';

export const SignUp: FC = () => {
  const classes = useStyles();
  const { accessStatus, registerData, isLogging } = useAppSelector(
    (state: RootState) => state.authSlice
  );

  return (
    <FullScreenBox
      flexDirection={'column'}
      display={'flex'}
      position={'relative'}
      justifyContent={'center'}>
      {accessStatus === null && !registerData && <UserInfo />}
      {accessStatus === ACCESS_STATUS.CONFIRM_SIGN_UP && registerData && (
        <VerifyCode />
      )}
      <Box className={classes.appNameTextView}>
        <AppNameText />
      </Box>
      <BackDrop open={isLogging} />
      <PageBlur />
    </FullScreenBox>
  );
};
