import {
  createAsyncThunk,
  createSelector,
  createSlice,
} from '@reduxjs/toolkit';
import { RootState } from 'app-redux/store';

const initialState = {
  isLoading: false,
  dashboardData: null,
  facebookAnalytics: null,
  instagramAnalytics: null,
  tiktokAnalytics: null,
  twitchAnalytics: null,
  xAnalytics: null,
  youtubeAnalytics: null,
};

export const getDashboardData = createAsyncThunk(
  'dashboardSlice/getDashboardData',
  async (input, thunkAPI) => {
    try {
    } catch (error) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

export const getFacebookAnalytics = createAsyncThunk(
  'dashboardSlice/getFacebookAnalytics',
  async (input, thunkAPI) => {
    try {
    } catch (error) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

export const dashboardSlice = createSlice({
  name: 'dashboardSlice',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getDashboardData.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getDashboardData.fulfilled, (state) => {
        state.isLoading = false;
      })
      .addCase(getDashboardData.rejected, (state) => {
        state.isLoading = false;
      });
  },
});

const selectDashboardState = (state: RootState) => state.dashboardSlice;

const selectIsLoadingDashboard = createSelector(
  selectDashboardState,
  (state) => state.isLoading
);
const selectDashboardData = createSelector(
  selectDashboardState,
  (state) => state.dashboardData
);
export { selectDashboardData, selectIsLoadingDashboard };

export default dashboardSlice.reducer;
