import { Avatar, Box, IconButton } from '@mui/material';
import React from 'react';
import { AppNameText } from 'components/app-name-text';
import {
  EmailOutlined,
  FavoriteBorder,
  NotificationsNoneOutlined,
} from '@material-ui/icons';
import { SearchBar } from 'components/search-bar';
import { avatar } from 'assets';
import { useStyles } from './styles';

export const ProfileHeader = () => {
  const classes = useStyles();
  return (
    <Box className={classes.mainContainer}>
      <AppNameText />
      <SearchBar placeholder="What service are you looking for today?" />
      <IconButton className={classes.iconBtn}>
        <NotificationsNoneOutlined />
      </IconButton>
      <IconButton className={classes.iconBtn}>
        <EmailOutlined />
      </IconButton>
      <IconButton className={classes.iconBtn}>
        <FavoriteBorder />
      </IconButton>
      <Avatar
        sizes="small"
        alt="Remy Sharp"
        src={avatar}
        sx={{ width: 35, height: 35 }}
      />
    </Box>
  );
};
