import { FullScreenBox, PageBlur } from 'components';
import React from 'react';
import { Box } from '@mui/material';
import { useStyles } from './styles';
import { OnBoardingBody } from './components';

export const OnBoarding = () => {
  const classes = useStyles();
  return (
    <FullScreenBox justifyContent={'center'}>
      <Box className={classes.innerContainer}>
        <OnBoardingBody />
      </Box>
      <PageBlur />
    </FullScreenBox>
  );
};
