import { makeStyles } from "@mui/styles";
import { Theme } from "@mui/material";
import { Colors } from "global-styles";

export const useStyles = makeStyles((theme: Theme) => ({
  mainContainer: {
    display: "flex",
    padding: "30px 100px !important",
    color: Colors.white,
    zIndex: 1,
    overflowY: "auto",
    "&::-webkit-scrollbar": { display: "none" },
  },
}));
