import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';
import { Colors } from 'global-styles';

export const useStyles = makeStyles((theme: Theme) => ({
  gridContainer: {
    padding: '20px !important',
    boxSizing: 'border-box',
    width: '100%',
  },
  reachText: {
    fontWeight: 'bold',
    fontSize: '20px',
    textAlign: 'left',
  },
  imageGrid: {
    background: Colors.primaryGlass,
    marginTop: 0,
    padding: '30px',
    borderRadius: '10px',
    height: 50,
    justifyContent: 'center',
    display: 'flex',
  },
  imageStyle: {
    width: '100%',
    height: '100%',
    display: 'block',
    margin: '0 auto',
    objectFit: 'contain',
  },
}));
