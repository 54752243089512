import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';
import { Colors } from 'global-styles';

export const useStyles = makeStyles((theme: Theme) => ({
  mainContainer: {
    display: 'flex',
    width: '233.29px',
    height: '100px',
    background: `linear-gradient(to right, ${Colors.gradientPrimary}, ${Colors.gradientSecondary})`,
    flexDirection: 'column',
    padding: '15px',
    justifyContent: 'space-between',
    borderRadius: '10px',
    userSelect: 'none',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
    transition: 'transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out',
    '&:hover': {
      transform: 'scale(1.1)',
    },
  },
  innerContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    gap: 6,
  },
  headerContainer: {
    height: 35,
    width: 35,
    borderRadius: '50%',
    background: Colors.lightGlass,
    alignItems: 'center',
    justifyContent: 'center',
    display: 'flex',
  },
  footerContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  percentageView: {
    padding: '5px',
    background: Colors.lightGlass,
    width: 'auto',
    borderRadius: '8px',
    display: 'flex',
    alignItems: 'center',
  },
  headingText: { fontWeight: 'bold' },
  countText: { fontSize: '20px', fontWeight: 'bold' },
  text2: { fontSize: '12px', fontWeight: '600' },
}));
