import { Box, Typography } from '@mui/material';
import React from 'react';
import { AppNameText } from 'components/app-name-text';
import { useStyles } from './styles';
import { BaseButton } from 'components';
import { useNavigate } from 'react-router-dom';
import { useAppSelector } from 'hooks';
import { RootState } from 'app-redux/store';

const pages = [
  { name: 'Home', to: '/home' },
  { name: 'Pricing', to: '/pricing' },
  { name: 'Login', to: '/sign-in' },
];

export const HomeHeader = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const { isLogin, user } = useAppSelector(
    (state: RootState) => state.authSlice
  );

  const handleNavigation = (to) => navigate(to);

  const handleCreatorLogin = () => navigate('/sign-up');
  const handleDashboard = () => navigate('/creator-dashboard');

  return (
    <Box className={classes.mainContainer}>
      <AppNameText />
      <Box className={classes.leftSide}>
        {!isLogin && (
          <>
            {pages.map((page, index) => (
              <Box
                key={index}
                className={classes.menuBtn}
                onClick={() => handleNavigation(page.to)}>
                <Typography textAlign={'center'}>{page.name}</Typography>
              </Box>
            ))}
          </>
        )}

        {isLogin ? (
          <BaseButton className={classes.creatorBtn} onClick={handleDashboard}>
            <Typography>{user?.name}</Typography>
          </BaseButton>
        ) : (
          <BaseButton
            className={classes.creatorBtn}
            onClick={handleCreatorLogin}>
            <Typography>Join as Creator</Typography>
          </BaseButton>
        )}
      </Box>
    </Box>
  );
};
