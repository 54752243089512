import { Box } from '@mui/material';
import { Colors } from 'global-styles';
import React from 'react';

export const PageBlur = () => {
  return <Box sx={styles.outerBlur} />;
};

const styles = {
  outerBlur: {
    position: 'absolute',
    width: '70%',
    height: '70%',
    alignSelf: 'center',
    background: Colors.gradientPrimary,
    border: `50px solid ${Colors.gradientSecondary}`,
    filter: 'blur(332px)',
    opacity: 0.7,
    '-webkit-filter': 'blur(332px)',
    '-webkit-transform': 'translate3d(0, 0, 0)',
  },
};
